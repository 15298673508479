module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/icons/omnico-logo_sq.png","icons":[{"src":"/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"name":"Konsolidacja kredytów","short_name":"Konsolidacja kredytów","description":"Konsolidacja kredytów","lang":"en","display":"standalone","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFFFFF","localize":[{"start_url":"/","lang":"pl","name":"Konsolidacja kredytów","short_name":"Konsolidacja kredytów","description":"Konsolidacja kredytów."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7bb3575ba9e4950e8c5bab585dee5ae4"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
